img {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
}
/*AppleSDGothicNeo*/
/*font-family: 'AppleSDGothicNeo', sans-serif;*/

/* @font-face {
    font-family: "AppleSDGothicNeo";
    font-weight: 700;
    font-style: normal;
    src: local("AppleSDGothicNeo Bold"), local("AppleSDGothicNeoB"),
      url("../assets/AppleSDGothicNeo/AppleSDGothicNeoB.ttf?#iefix")
        format("truetype");
  }
  @font-face {
    font-family: "AppleSDGothicNeo";
    font-weight: 400;
    font-style: normal;
    src: local("AppleSDGothicNeo Regular"), local("AppleSDGothicNeoR"),
      url("../assets/AppleSDGothicNeo/AppleSDGothicNeoR.ttf?#iefix")
        format("truetype");
  }
   */
